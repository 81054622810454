var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "d-flex justify-content-end mb-1" }, [
        _vm.$can("Carta de Crédito - Cadastrar", "Comercial")
          ? _c(
              "button",
              {
                staticClass: "btn btn-primary px-5",
                staticStyle: { "min-width": "160px" },
                on: {
                  click: function ($event) {
                    return _vm.openModalCreate()
                  },
                },
              },
              [_vm._v(" Cadastrar ")]
            )
          : _vm._e(),
      ]),
      _c(
        "b-card",
        { staticClass: "mb-1" },
        [
          _c(
            "b-form",
            {
              ref: "formFilter",
              staticClass: "m-0",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.getData()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c("b-col", { attrs: { cols: "12", md: "1" } }, [
                    _c("div", { staticClass: "form-group mb-md-0" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filters.id,
                            expression: "filters.id",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "tel", placeholder: "ID" },
                        domProps: { value: _vm.filters.id },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.filters, "id", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "7" } }, [
                    _c(
                      "div",
                      { staticClass: "form-group mb-md-0" },
                      [
                        _c(
                          "v-select",
                          {
                            attrs: {
                              label: "title",
                              placeholder: "ID do PEDIDO",
                              "item-text": "title",
                              "item-value": "code",
                              options: _vm.optionsOrders,
                              filterable: false,
                            },
                            on: { search: _vm.fetchCustomerOrders },
                            model: {
                              value: _vm.filters.order,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "order", $$v)
                              },
                              expression: "filters.order",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "3" } }, [
                    _c(
                      "div",
                      { staticClass: "form-group mb-md-0" },
                      [
                        _c("flat-pickr", {
                          staticClass: "form-control",
                          attrs: {
                            config: {
                              mode: "range",
                              altInput: true,
                              dateFormat: "Y-m-d",
                              altFormat: "d/m/Y",
                              locale: "pt",
                            },
                          },
                          model: {
                            value: _vm.filters.rangeDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "rangeDate", $$v)
                            },
                            expression: "filters.rangeDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "1" } }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-block",
                        attrs: { type: "submit" },
                      },
                      [
                        _vm.btnSearch
                          ? _c(
                              "div",
                              [
                                _c("b-spinner", {
                                  attrs: { label: "Loading...", small: "" },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c("feather-icon", {
                                  staticClass: "cursor-pointer cursor",
                                  attrs: { icon: "SearchIcon", size: "16" },
                                }),
                              ],
                              1
                            ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.fields,
              "primary-key": "id",
              "show-empty": "",
              "empty-text": "Nenhum registro encontrado",
            },
            scopedSlots: _vm._u([
              {
                key: "cell(action)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex-between" },
                      [
                        _c("feather-icon", {
                          staticClass: "cursor-pointer cursor text-danger",
                          attrs: { icon: "TrashIcon", size: "16" },
                          on: {
                            click: function ($event) {
                              return _vm.confirmDelete(item.uuid)
                            },
                          },
                        }),
                        _c("feather-icon", {
                          staticClass: "cursor-pointer cursor",
                          attrs: { icon: "EyeIcon", size: "16" },
                          on: {
                            click: function ($event) {
                              return _vm.openModal(item.uuid)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(total)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("toCurrency")(item.total)) + " "
                    ),
                  ]
                },
              },
              {
                key: "cell(order_id)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "b-link",
                      {
                        attrs: {
                          to: {
                            name: "transactions-orders-preview",
                            params: { uuid: item.order_uuid },
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.order_id) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("CustomPaginateTable", {
            attrs: {
              "rows-table": _vm.rowsTable,
              "current-page": _vm.filters.currentPage,
              "per-page": _vm.filters.perPage,
              "total-rows": _vm.totalRows,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-letter-credits",
            "modal-class": "modal-import",
            title: "Carta de crédito",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            centered: "",
            "hide-footer": "",
            size: "lg",
          },
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitLetterCredits.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c("LabelCustom", {
                        attrs: { required: true, text: "Cliente" },
                      }),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "v-select",
                            {
                              class: {
                                "is-invalid": _vm.$v.item.customer.$error,
                              },
                              attrs: {
                                disabled: _vm.mode === "view",
                                label: "title",
                                filterable: false,
                                "item-text": "title",
                                "item-value": "code",
                                options: _vm.optionsCustomers,
                              },
                              on: { search: _vm.fetchCustomers },
                              model: {
                                value: _vm.item.customer,
                                callback: function ($$v) {
                                  _vm.$set(_vm.item, "customer", $$v)
                                },
                                expression: "item.customer",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "no-options" },
                                  slot: "no-options",
                                },
                                [_vm._v(" Nenhum registro encontrado ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.mode === "create"
                    ? _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c("LabelCustom", {
                            attrs: { required: true, text: "Pedidos" },
                          }),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "v-select",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.mode === "view" || !_vm.item.customer,
                                    filterable: false,
                                    clearable: true,
                                    label: "id",
                                    reduce: function (option) {
                                      return option.uuid
                                    },
                                    options: _vm.optionsOrders,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "option",
                                        fn: function (item) {
                                          return [
                                            _c("div", { staticClass: "py-2" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-between align-items-center mb-1",
                                                },
                                                [
                                                  _c("div", [
                                                    _c("strong", [
                                                      _vm._v(
                                                        "Pedido #" +
                                                          _vm._s(item.id)
                                                      ),
                                                    ]),
                                                  ]),
                                                  _c(
                                                    "b-badge",
                                                    {
                                                      attrs: {
                                                        variant:
                                                          _vm.getStatusVariant(
                                                            item.status.name
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.status.name
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-between small text-muted mb-1",
                                                },
                                                [
                                                  _c("div", [
                                                    _c("i", {
                                                      staticClass:
                                                        "bi bi-calendar-date",
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("datePT")(
                                                            item.created_at,
                                                            true
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                  _c("div", [
                                                    _c("i", {
                                                      staticClass:
                                                        "bi bi-stripe",
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.method.name
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-between align-items-center",
                                                },
                                                [
                                                  _c("div", [
                                                    _c("i", {
                                                      staticClass: "bi bi-box",
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.products.length
                                                        ) +
                                                        " produto" +
                                                        _vm._s(
                                                          item.products.length >
                                                            1
                                                            ? "s"
                                                            : ""
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "text-right",
                                                    },
                                                    [
                                                      _c("div", [
                                                        _c("i", {
                                                          staticClass:
                                                            "bi bi-cash",
                                                        }),
                                                        _vm._v(" Total: "),
                                                        _c("strong", [
                                                          _vm._v(
                                                            "R$ " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "toCurrency"
                                                                )(item.total)
                                                              )
                                                          ),
                                                        ]),
                                                      ]),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-muted small",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " 🏷️ Desconto: R$ " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "toCurrency"
                                                                )(
                                                                  item.total_discount
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "small text-muted",
                                                },
                                                _vm._l(
                                                  item.products,
                                                  function (product) {
                                                    return _c(
                                                      "div",
                                                      { key: product.id },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "bi bi-dot",
                                                        }),
                                                        _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              product.title
                                                            )
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          " — " +
                                                            _vm._s(
                                                              product.product_category_name
                                                            )
                                                        ),
                                                        product.classroom_name
                                                          ? _c("span", [
                                                              _vm._v(
                                                                " #" +
                                                                  _vm._s(
                                                                    product.classroom_name
                                                                  )
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v(
                                                          " — " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "toCurrency"
                                                              )(product.price)
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                      {
                                        key: "selected-option",
                                        fn: function (item) {
                                          return [
                                            _c("strong", [
                                              _vm._v(
                                                "Pedido #" +
                                                  _vm._s(item.id) +
                                                  " - R$ " +
                                                  _vm._s(
                                                    _vm._f("toCurrency")(
                                                      item.total
                                                    )
                                                  ) +
                                                  " - " +
                                                  _vm._s(item.status.name) +
                                                  " - "
                                              ),
                                              _c("span", [
                                                _c("i", {
                                                  staticClass: "bi bi-box",
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.products.length
                                                    ) +
                                                    " produtos"
                                                ),
                                              ]),
                                              _vm._v(
                                                " - " +
                                                  _vm._s(
                                                    _vm._f("datePT")(
                                                      item.created_at,
                                                      true
                                                    )
                                                  )
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4068966604
                                  ),
                                  model: {
                                    value: _vm.order,
                                    callback: function ($$v) {
                                      _vm.order = $$v
                                    },
                                    expression: "order",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      slot: "no-options",
                                    },
                                    [_vm._v(" Nenhum registro encontrado ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "col-md-8" },
                    [
                      _c("LabelCustom", {
                        attrs: { required: true, text: "Unidade de negócio" },
                      }),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "v-select",
                            {
                              class: {
                                "is-invalid": _vm.$v.item.business_unit.$error,
                              },
                              attrs: {
                                disabled: _vm.mode === "view",
                                label: "title",
                                "item-text": "title",
                                "item-value": "code",
                                options: _vm.optionsBusinessUnits,
                              },
                              on: { search: _vm.fetchBusinessUnits },
                              model: {
                                value: _vm.item.business_unit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.item, "business_unit", $$v)
                                },
                                expression: "item.business_unit",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "no-options" },
                                  slot: "no-options",
                                },
                                [_vm._v(" Nenhum registro encontrado ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.mode === "create"
                    ? _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c("LabelCustom", {
                            attrs: { required: true, text: "Passaporte" },
                          }),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "v-select",
                                {
                                  class: {
                                    "is-invalid": _vm.$v.item.passport.$error,
                                  },
                                  attrs: {
                                    label: "title",
                                    "item-text": "title",
                                    "item-value": "value",
                                    clearable: false,
                                    reduce: function (option) {
                                      return option.value
                                    },
                                    options: _vm.optionsPassaport,
                                  },
                                  model: {
                                    value: _vm.item.passport,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "passport", $$v)
                                    },
                                    expression: "item.passport",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      slot: "no-options",
                                    },
                                    [_vm._v(" Nenhum registro encontrado ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "col-md-4" },
                    [
                      _c("LabelCustom", {
                        attrs: { required: true, text: "Horas" },
                      }),
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: ["####:##"],
                              expression: "['####:##']",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.hours,
                              expression: "item.hours",
                            },
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid":
                              _vm.$v.item.hours.$error ||
                              !_vm.validCourseHours(_vm.item.hours),
                          },
                          attrs: {
                            disabled: _vm.mode === "view",
                            placeholder: "000:00",
                            type: "tel",
                          },
                          domProps: { value: _vm.item.hours },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.item, "hours", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                  _vm.mode === "create"
                    ? _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("LabelCustom", {
                            attrs: { required: true, text: "Validade" },
                          }),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("flat-pickr", {
                                staticClass: "form-control",
                                attrs: {
                                  config: {
                                    mode: "single",
                                    altInput: true,
                                    dateFormat: "Y-m-d",
                                    altFormat: "d/m/Y",
                                    locale: "pt",
                                    minDate: new Date(),
                                  },
                                },
                                model: {
                                  value: _vm.item.validate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "validate", $$v)
                                  },
                                  expression: "item.validate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.mode === "create"
                    ? _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("LabelCustom", {
                            attrs: { required: false, text: "Valor" },
                          }),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "money",
                                _vm._b(
                                  {
                                    staticClass: "form-control",
                                    attrs: { placeholder: "0,00" },
                                    model: {
                                      value: _vm.item.total,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "total", $$v)
                                      },
                                      expression: "item.total",
                                    },
                                  },
                                  "money",
                                  _vm.money,
                                  false
                                )
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.mode === "view"
                    ? _c(
                        "div",
                        { staticClass: "col-md-5" },
                        [
                          _c("LabelCustom", {
                            attrs: { required: true, text: "Status" },
                          }),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "v-select",
                                {
                                  attrs: {
                                    disabled: _vm.mode === "view",
                                    label: "title",
                                    searchable: false,
                                    placeholder: "",
                                    "item-text": "title",
                                    "item-value": "code",
                                    options: _vm.optionsStatus,
                                  },
                                  model: {
                                    value: _vm.item.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "status", $$v)
                                    },
                                    expression: "item.status",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      slot: "no-options",
                                    },
                                    [_vm._v(" Nenhum registro encontrado ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.mode === "view"
                ? _c(
                    "div",
                    [
                      _c("h5", [_vm._v("Histórico de uso:")]),
                      _c(
                        "b-list-group",
                        { staticClass: "list-group-messages" },
                        _vm._l(_vm.item.logs, function (item, index) {
                          return _c(
                            "b-list-group-item",
                            { key: "item-" + index },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex gap-3",
                                  staticStyle: { "column-gap": "13px" },
                                },
                                [
                                  _c("div", [
                                    _vm._v(" ID Pedido: "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "btn-link cursor-pointer",
                                        on: {
                                          click: function ($event) {
                                            return _vm.$router.push({
                                              name: "transactions-orders-preview",
                                              params: { uuid: item.order_uuid },
                                            })
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.order_id) + " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      "Total: " +
                                        _vm._s(
                                          _vm._f("toCurrency")(item.total_used)
                                        )
                                    ),
                                  ]),
                                  _c("div", { staticClass: "offset-md-1" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("datePT")(
                                            item.created_at,
                                            true
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-row justify-content-end mt-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "col-md-12 col-12 text-left" },
                            [
                              _vm._v(
                                " Previsão de validade da carta de crédito "
                              ),
                              _c("strong", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(_vm.validate)),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.mode !== "view"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "form-row justify-content-end modal-footer-custom",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-7 col-12" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light float-left",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.hide(
                                    "modal-letter-credits"
                                  )
                                },
                              },
                            },
                            [_vm._v(" Fechar ")]
                          ),
                          _c("ButtonsActionsFooter", {
                            attrs: {
                              variant: "success",
                              submited: _vm.submited,
                              text: "Salvar",
                              subtext: "Aguarde...",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }