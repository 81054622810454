<template>
  <div>
    <div class="d-flex justify-content-end mb-1">
      <button
        v-if="$can('Carta de Crédito - Cadastrar', 'Comercial')"
        class="btn btn-primary px-5"
        style="min-width: 160px"
        @click="openModalCreate()"
      >
        Cadastrar
      </button>
    </div>
    <b-card class="mb-1">
      <b-form class="m-0" ref="formFilter" @submit.prevent="getData()">
        <div class="form-row">
          <b-col cols="12" md="1">
            <div class="form-group mb-md-0">
              <input
                type="tel"
                placeholder="ID"
                v-model="filters.id"
                class="form-control"
              />
            </div>
          </b-col>

          <b-col cols="12" md="7">
            <div class="form-group mb-md-0">
              <v-select
                label="title"
                placeholder="ID do PEDIDO"
                item-text="title"
                item-value="code"
                :options="optionsOrders"
                :filterable="false"
                v-model="filters.order"
                @search="fetchCustomerOrders"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </b-col>

          <b-col cols="12" md="3">
            <div class="form-group mb-md-0">
              <flat-pickr
                v-model="filters.rangeDate"
                class="form-control"
                :config="{
                  mode: 'range',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </b-col>

          <b-col cols="12" md="1">
            <button type="submit" class="btn btn-primary btn-block">
              <div v-if="btnSearch">
                <b-spinner label="Loading..." small />
              </div>
              <div v-else>
                <feather-icon
                  icon="SearchIcon"
                  class="cursor-pointer cursor"
                  size="16"
                />
              </div>
            </button>
          </b-col>
        </div>
      </b-form>
    </b-card>

    <b-card no-body class="mb-0">
      <b-table
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="fields"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
      >
        <template #cell(action)="{ item }">
          <div class="d-flex-between">
            <feather-icon
              icon="TrashIcon"
              size="16"
              @click="confirmDelete(item.uuid)"
              class="cursor-pointer cursor text-danger"
            />

            <feather-icon
              icon="EyeIcon"
              size="16"
              @click="openModal(item.uuid)"
              class="cursor-pointer cursor"
            />
          </div>
        </template>
        <template #cell(total)="{ item }">
          {{ item.total | toCurrency }}
        </template>
        <template #cell(order_id)="{ item }">
          <b-link
            :to="{
              name: 'transactions-orders-preview',
              params: { uuid: item.order_uuid },
            }"
          >
            {{ item.order_id }}
          </b-link>
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="filters.currentPage"
        :per-page="filters.perPage"
        :total-rows="totalRows"
      />
    </b-card>

    <b-modal
      id="modal-letter-credits"
      modal-class="modal-import"
      title="Carta de crédito"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
      size="lg"
    >
      <form @submit.prevent="submitLetterCredits">
        <div class="form-row">
          <div class="col-md-12">
            <LabelCustom :required="true" :text="'Cliente'" />
            <div class="form-group">
              <v-select
                :disabled="mode === 'view'"
                label="title"
                :filterable="false"
                item-text="title"
                item-value="code"
                :options="optionsCustomers"
                v-model="item.customer"
                :class="{ 'is-invalid': $v.item.customer.$error }"
                @search="fetchCustomers"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>

          <div class="col-md-12" v-if="mode === 'create'">
            <LabelCustom :required="true" :text="'Pedidos'" />
            <div class="form-group">
              <v-select
                :disabled="mode === 'view' || !item.customer"
                :filterable="false"
                :clearable="true"
                label="id"
                :reduce="(option) => option.uuid"
                :options="optionsOrders"
                v-model="order"
              >
                <template #option="item">
                  <div class="py-2">
                    <div
                      class="d-flex justify-content-between align-items-center mb-1"
                    >
                      <div>
                        <strong>Pedido #{{ item.id }}</strong>
                      </div>
                      <b-badge :variant="getStatusVariant(item.status.name)">
                        {{ item.status.name }}
                      </b-badge>
                    </div>

                    <div
                      class="d-flex justify-content-between small text-muted mb-1"
                    >
                      <div>
                        <i class="bi bi-calendar-date"></i>
                        {{ item.created_at | datePT(true) }}
                      </div>
                      <div>
                        <i class="bi bi-stripe"></i>
                        {{ item.method.name }}
                      </div>
                    </div>

                    <!-- Resumo geral -->
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div>
                        <i class="bi bi-box"></i>
                        {{ item.products.length }} produto{{
                          item.products.length > 1 ? "s" : ""
                        }}
                      </div>
                      <div class="text-right">
                        <div>
                          <i class="bi bi-cash"></i>
                          Total:
                          <strong>R$ {{ item.total | toCurrency }}</strong>
                        </div>
                        <div class="text-muted small">
                          🏷️ Desconto: R$ {{ item.total_discount | toCurrency }}
                        </div>
                      </div>
                    </div>

                    <!-- Lista de produtos -->
                    <div class="small text-muted">
                      <div v-for="product in item.products" :key="product.id">
                        <i class="bi bi-dot"></i>
                        <strong>{{ product.title }}</strong> —
                        {{ product.product_category_name
                        }}<span v-if="product.classroom_name">
                          #{{ product.classroom_name }}</span
                        >
                        —
                        {{ product.price | toCurrency }}
                      </div>
                    </div>
                  </div>
                </template>

                <template #selected-option="item">
                  <strong
                    >Pedido #{{ item.id }} - R$ {{ item.total | toCurrency }} -
                    {{ item.status.name }} -
                    <span>
                      <i class="bi bi-box"></i>
                      {{ item.products.length }} produtos</span
                    >
                    - {{ item.created_at | datePT(true) }}</strong
                  >
                </template>

                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>

          <div class="col-md-8">
            <LabelCustom :required="true" :text="'Unidade de negócio'" />
            <div class="form-group">
              <v-select
                :disabled="mode === 'view'"
                label="title"
                item-text="title"
                :class="{ 'is-invalid': $v.item.business_unit.$error }"
                item-value="code"
                @search="fetchBusinessUnits"
                :options="optionsBusinessUnits"
                v-model="item.business_unit"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>

          <div class="col-md-4" v-if="mode === 'create'">
            <LabelCustom :required="true" :text="'Passaporte'" />
            <div class="form-group">
              <v-select
                label="title"
                item-text="title"
                item-value="value"
                :class="{ 'is-invalid': $v.item.passport.$error }"
                :clearable="false"
                :reduce="(option) => option.value"
                :options="optionsPassaport"
                v-model="item.passport"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>

          <div class="col-md-4">
            <LabelCustom :required="true" :text="'Horas'" />
            <div class="form-group">
              <input
                :disabled="mode === 'view'"
                v-mask="['####:##']"
                placeholder="000:00"
                v-model="item.hours"
                :class="{
                  'is-invalid':
                    $v.item.hours.$error || !validCourseHours(item.hours),
                }"
                type="tel"
                class="form-control"
              />
            </div>
          </div>

          <b-col cols="12" md="4" v-if="mode === 'create'">
            <LabelCustom :required="true" :text="'Validade'" />
            <div class="form-group">
              <flat-pickr
                v-model="item.validate"
                class="form-control"
                :config="{
                  mode: 'single',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                  minDate: new Date(),
                }"
              />
            </div>
          </b-col>

          <b-col cols="12" md="4" v-if="mode === 'create'">
            <LabelCustom :required="false" :text="'Valor'" />
            <div class="form-group">
              <money
                class="form-control"
                v-model="item.total"
                placeholder="0,00"
                v-bind="money"
              ></money>
            </div>
          </b-col>

          <div class="col-md-5" v-if="mode === 'view'">
            <LabelCustom :required="true" :text="'Status'" />
            <div class="form-group">
              <v-select
                :disabled="mode === 'view'"
                label="title"
                :searchable="false"
                placeholder=""
                item-text="title"
                item-value="code"
                :options="optionsStatus"
                v-model="item.status"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>
        </div>
        <div v-if="mode === 'view'">
          <h5>Histórico de uso:</h5>
          <b-list-group class="list-group-messages">
            <b-list-group-item
              v-for="(item, index) in item.logs"
              :key="`item-${index}`"
            >
              <div style="column-gap: 13px" class="d-flex gap-3">
                <div>
                  ID Pedido:
                  <span
                    class="btn-link cursor-pointer"
                    @click="
                      $router.push({
                        name: 'transactions-orders-preview',
                        params: { uuid: item.order_uuid },
                      })
                    "
                  >
                    {{ item.order_id }}
                  </span>
                </div>
                <div>Total: {{ item.total_used | toCurrency }}</div>
                <div class="offset-md-1">
                  {{ item.created_at | datePT(true) }}
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>

          <div class="form-row justify-content-end mt-2">
            <div class="col-md-12 col-12 text-left">
              Previsão de validade da carta de crédito
              <strong class="text-danger">{{ validate }}</strong>
            </div>
          </div>
        </div>
        <div
          class="form-row justify-content-end modal-footer-custom"
          v-if="mode !== 'view'"
        >
          <div class="col-md-7 col-12">
            <button
              type="button"
              @click="$bvModal.hide('modal-letter-credits')"
              class="btn btn-light float-left"
            >
              Fechar
            </button>
            <ButtonsActionsFooter
              variant="success"
              :submited="submited"
              text="Salvar"
              subtext="Aguarde..."
            />
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BIcon,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BTable,
  BButton,
  BPagination,
  BMedia,
  BTooltip,
  BBadge,
  BFormRow,
  BSpinner,
  BLink,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";

import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import { Money } from "v-money";

import LabelCustom from "@/components/form/LabelCustom.vue";

import { required } from "vuelidate/lib/validators";

export default {
  components: {
    flatPickr,
    CustomPaginateTable,
    BCard,
    BFormRow,
    BForm,
    BIcon,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
    BTable,
    BButton,
    BPagination,
    BMedia,
    BTooltip,
    BBadge,
    vSelect,
    LabelCustom,
    BLink,
    BListGroup,
    BListGroupItem,
    ButtonsActionsFooter,
    Money,
  },
  data() {
    return {
      filters: {
        rangeDate: `${moment()
          .subtract("1", "week")
          .format("YYYY-MM-DD")} até ${moment().format("YYYY-MM-DD")}`,
        order_id: "",
        customer: "",
        id: "",
        currentPage: 1,
        perPage: 100,
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      order: "",
      mode: "view",
      validate: 0,
      submited: false,
      btnSearch: false,
      filter: null,
      filterOn: [],
      totalRows: 0,
      rowsTable: 0,
      dataItems: [],
      fields: [
        {
          key: "id",
          label: "ID",
          class: "text-center",
        },
        {
          key: "customer_name",
          label: "Cliente",
          class: "text-left",
        },
        {
          key: "total",
          label: "Total",
          class: "text-center",
        },
        {
          key: "hours",
          label: "Horas",
          class: "text-center",
        },
        {
          key: "business_unit_name",
          label: "U. Negócio",
          class: "text-center",
        },
        {
          key: "user_first_name",
          label: "Responsável",
          class: "text-center",
        },
        {
          key: "status",
          label: "Status",
          class: "text-center",
        },
        {
          key: "created_at",
          label: "Data",
          class: "text-center",
        },
        {
          key: "logs",
          label: "Log",
          class: "text-center",
          thStyle: "width: 30px",
        },
        {
          key: "action",
          label: "",
          class: "text-center",
          thStyle: "width: 80px",
        },
      ],
      optionsCustomers: [],
      item: {
        uuid: "",
        customer: "",
        hours: "",
        active: false,
        status: "",
        business_unit: "",
        validate: "",
        total: null,
        passport: null,
      },
      optionsOrders: [],
      optionsBusinessUnits: [],
      optionsStatus: [],
      optionsPassaport: [
        {
          value: 1,
          title: "Sim",
        },
        {
          value: 0,
          title: "Não",
        },
      ],
      uuid: "",
    };
  },
  validations: {
    item: {
      customer: {
        required,
      },
      hours: {
        required,
      },
      business_unit: {
        required,
      },
      validate: {
        required,
      },
      passport: {
        required,
      },
    },
    formEdit: {
      order: {
        required,
      },
      hours: {
        required,
      },
      business_unit: {
        required,
      },
      status: {
        required,
      },
      scholarity: {
        required,
      },
    },
  },
  watch: {
    "item.customer": {
      immediate: false,
      handler(newVal) {
        if (newVal) {
          this.fetchOrders(newVal);
          this.item.orders = null;
        } else {
          this.optionsOrders = [];
          this.item.orders = null;
        }
      },
    },
  },
  methods: {
    getStatusVariant(statusName) {
      const statusMap = {
        Pendente: "warning",
        Pago: "success",
        Cancelado: "danger",
        Processando: "info",
        "Aguardando Pagamento": "secondary",
        "Em cancelamento": "dark",
      };

      return statusMap[statusName] || "primary";
    },
    async fetchOrders(customer) {
      await this.$store
        .dispatch("Customer/getOrders", customer.code)
        .then((res) => {
          this.optionsOrders = res;
        });
    },
    validCourseHours(value) {
      if (!value) return false;
      const regex = /^\d{1,4}:[0-5][0-9]$/;
      return regex.test(value);
    },
    clearForm() {
      this.item = {
        uuid: "",
        customer: "",
        order: "",
        hours: "",
        active: false,
        status: "",
        scholarity: "",
        business_unit: "",
        validate: "",
        total: 0,
        passport: null,
      };
      this.order = "";
      this.$v.$reset();
    },
    closeModal() {
      this.clearForm();
      this.$bvModal.hide("modal-letter-credits");
    },
    async orderSelected(obj) {
      await this.$store
        .dispatch("Customer/getOrderDetails", {
          customerUuid: obj.customer_uuid,
          orderUuid: obj.code,
        })
        .then((res) => {
          console.log(res);
        });
    },
    async openModal(uuid, mode = "view") {
      this.mode = mode;
      this.item.uuid = uuid;
      await this.$store
        .dispatch("LetterCredit/edit", this.item.uuid)
        .then((res) => {
          this.item = res;
          this.$bvModal.show("modal-letter-credits");
        });
    },
    async fetchBusinessUnits(term) {
      if (term.length > 2) {
        this.optionsBusinessUnits = await this.$store.dispatch(
          "BusinessUnit/forSelect",
          term
        );
      }
    },
    async fetchCustomers(term, loading) {
      this.optionsCustomers = [];
      if (term.length > 2) {
        loading(true);
        this.optionsCustomers = await this.$store
          .dispatch("Customer/forSelect", term)
          .finally(() => {
            loading(false);
          });
      }
    },
    async fetchCustomerOrders(term, loading) {
      if (term.length > 2) {
        this.optionsOrders = [];
        loading(true);
        this.optionsOrders = await this.$store
          .dispatch("Customer/forSelectOrders", term)
          .finally(() => {
            loading(false);
          });
      }
    },
    async getData() {
      this.btnSearch = true;
      let filtersSearch = {
        rangeDate: this.filters.rangeDate ?? "",
        order: this.filters.order,
        id: this.filters.id ?? "",
        currentPage: this.filters.currentPage,
        perPage: this.filters.perPage,
      };

      this.$store
        .dispatch("LetterCredit/all", filtersSearch)
        .then((res) => {
          if (res) {
            this.dataItems = res.data;
            this.rowsTable = res.data.length;
            this.totalRows = res.meta.total;
            this.filters.currentPage = res.meta.current_page;
          }
        })
        .finally(() => {
          this.btnSearch = false;
        });
    },
    submitLetterCredits() {
      this.$v.item.$touch();

      if (!this.$v.item.$error) {
        this.submited = true;

        const data = {
          customer: this.item.customer.code,
          hours: this.item.hours,
          order: this.order,
          business_unit: this.item.business_unit.code,
          validade: this.item.validate,
          passport: this.item.passport,
          total: this.item.total,
          price_hour: this.calculatePriceHour(this.item.hours, this.item.total),
        };

        if (this.item.uuid) {
          this.$store
            .dispatch("LetterCredit/update", {
              uuid: this.item.uuid,
              data: data,
            })
            .then(() => {
              this.notifyDefault("success");
              this.$bvModal.hide("modal-letter-credits");
              this.getData();
              this.$v.item.reset();
            })
            .finally(() => {
              this.submited = false;
            });
        } else {
          this.$store
            .dispatch("LetterCredit/store", data)
            .then(() => {
              this.notifyDefault("success");
              this.$bvModal.hide("modal-letter-credits");
              this.getData();
              this.$v.item.$reset();
            })
            .finally(() => {
              this.submited = false;
              this.clearForm();
              this.$bvModal.hide("modal-letter-credits");
            });
        }
      } else {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    openModalCreate() {
      this.mode = "create";
      this.clearForm();
      this.$bvModal.show("modal-letter-credits");
    },
    calculatePriceHour(time, total) {
      if (!time || !/^\d+:[0-5][0-9]$/.test(time)) return undefined;

      const [hoursStr, minutesStr] = time.split(":");
      const hours = parseInt(hoursStr, 10);
      const minutes = parseInt(minutesStr, 10);
      const totalHours = hours + minutes / 60;

      if (totalHours === 0) return 0;

      const price = total / totalHours;

      return parseFloat(price.toFixed(2)); // Retorna número com 2 casas
    },
    submitUpdate() {
      this.$v.formEdit.$touch();

      if (!this.$v.formEdit.$error) {
        this.submited = true;
        const data = {
          hours: this.formEdit.hours,
          order: this.formEdit.order.code,
          business_unit: this.formEdit.business_unit.code,
          status: this.formEdit.status.code,
        };

        this.$store
          .dispatch("LetterCredit/update", { uuid: this.item.uuid, data: data })
          .then(() => {
            this.notifyDefault("success");
            this.$bvModal.hide("modal-letter-credits");
            this.getData();
            this.$v.formEdit.reset();
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Tem certeza?",
        text: "Só será possível deletar caso não tenha nenhum cadastro vinculado.",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("LetterCredit/delete", uuid).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Operação realizada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
  },
  directives: {
    Ripple,
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
    this.$root.$off("open:export-table");
    this.$root.$off("open:modal-letter-credits");
  },
  async mounted() {
    this.getData();

    this.validate = moment().add(1, "year").format("DD/MM/YYYY");

    this.$root.$on("paginate:update", (res) => {
      this.filters.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.filters.perPage = res._perPage;
      this.getData();
    });

    this.$root.$on("open:export-table", () => {
      this.$swal({
        title: "Exportar Pedidos",
        text: "Enviaremos para o seu e-mail e WhatsApp, o link para você baixar o relatório de pedidos.",
        iconHtml: '<i class="bi bi-download"></i>',
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Gerar relatório",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("Order/export", {
              filters: {
                rangeDate: this.filters.rangeDate ?? "",
                status: this.filters.status ? this.filters.status.code : "",
                method: this.filters.method ? this.filters.method.code : "",
                product: this.filters.product ? this.filters.product.code : "",
                coupon: this.filters.coupon ? this.filters.coupon.code : "",
                category: this.filters.category
                  ? this.filters.category.code
                  : "",
                cpf: this.filters.cpf ?? "",
                id: this.filters.coupon ?? "",
              },
            })
            .then((res) => {
              console.log(res);
            })
            .finally(() => {
              this.submitedFilter = false;
            });
        }
      });
    });

    this.$root.$on("open:modal-letter-credits", () => {
      this.mode = "create";
      this.clearForm();
      this.$bvModal.show("modal-letter-credits");
    });

    this.$store.dispatch("LetterCredit/forSelectStatus").then((res) => {
      this.optionsStatus = res;
    });
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.swal2-icon-content {
  i {
    font-size: 2rem;
    color: #28c76f;
  }
}

.swal2-icon-show {
  border-color: #28c76f;
}
</style>
